<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.new_m", {
                m: $t("message.filial"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item :label="$t('message.title')" class="label_mini" prop="title">
                  <el-input
                    :placeholder="$t('message.title')"
                    v-model="form.title"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('message.name')" class="label_mini" prop="name">
                  <el-input
                    :placeholder="$t('message.name')"
                    v-model="form.name"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('message.country_id')" class="label_mini" prop="country_id">
                  <select-country
                    :size="'medium'"
                    :placeholder="columns.country_id.title"
                    :id="form.country_id"
                    v-model="form.country_id"
                  >
                  </select-country>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('message.city_id')" class="label_mini" prop="city_id">
                  <select-city
                    :size="'medium'"
                    :placeholder="columns.city_id.title"
                    :id="form.city_id"
                    :country_id="form.country_id"
                    v-model="form.city_id"
                  >
                  </select-city>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <crm-phone-input
                    :inputValue="form.phone_1" 
                    v-model="form.phone_1"
                    @getPhoneStatus="getPhoneStatus"
                    :form_prop="'phone'"
                    :country_code="form.phone_country_code_1"
                ></crm-phone-input> 
              </el-col>
              <!-- end-col -->
              <el-col :span="12">
                <crm-phone-input
                    :inputValue="form.phone_2" 
                    v-model="form.phone_2"
                    @getPhoneStatus="getPhoneStatus"
                    :form_prop="'phone'"
                    :country_code="form.phone_country_code_2"
                ></crm-phone-input> 
              </el-col>
              <!-- end-col -->
              <el-col :span="24">
                <el-form-item
                  :label="$t('message.categories')"
                  class="label_mini"
                  prop="categories"
                >
                  <select-categories
                    ref="Categories"
                    v-model="form.categories"
                    :category_ids="form.categories"
                    :size="'medium'"
                    :data="JSON.parse(JSON.stringify(this.categories)).filter(item => item.has_child === false)"
                    :placeholder="$t('message.categories')"
                  >
                  </select-categories>
                </el-form-item>
              </el-col>
              <!-- end-col -->
              <el-col :span="24">
                <el-form-item :label="$t('message.location')" class="label_mini" prop="address">
                  <el-input
                    :placeholder="$t('message.location')"
                    v-model="form.address"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end-col -->
              <el-col :span="24">
                <el-form-item :label="$t('message.plugin')" class="label_mini" prop="address">
                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="false"
                      action="/"
                      :on-change="updateImageList"
                      list-type="picture-card"
                      accept="image/jpeg, image/png"
                      :auto-upload="false"
                    >
                      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                      <i v-else class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
              </el-col>
              <!-- end-col -->
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCategories from "@/components/multiSelects/multi-select-categories";
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";

export default {
  mixins: [form, drawer],
  components: {
    selectCountry,
    selectCity,
    selectCategories,
  },
  data() {
    return {
      imageUrl: '',
      updateImage: [],
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "filials/rules",
      model: "filials/model",
      columns: "filials/columns",
      categories: "categories/inventory",
    }),
  },
  methods: {
    ...mapActions({
      save: "filials/store",
    }),
    updateImageList(file) {
      if(file.size < 5200000){
        this.imageUrl = URL.createObjectURL(file.raw);
        this.updateImage = [];
        this.updateImage.push(file.raw);
      }else{
        this.$message({
            type: "warning",
            message: this.$t('message.file_size_limit_warning', {number: 5})
          });
      }
    },
    getPhoneStatus({is_valid, countryCode}){
        this.form.phone_country_code = countryCode;
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;

          let formData = new FormData();

          for (const key in this.form) {
            if (key != "plugin") {
              if (this.form[key]) {
                formData.append(key, this.form[key]);
              } else {
                formData.append(key, "");
              }
            }
          }

          if (_.size(this.updateImage) > 0) {
            for (const key in this.updateImage) {
              if (this.updateImage.hasOwnProperty(key)) {
                const element = this.updateImage[key];
                formData.append("plugin", element);
              }
            }
          }

          this.save(formData)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
